* {
  font-family: 'Times New Roman', Times, serif;
}
.App {
  text-align: center;
}

body {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  text-align: left;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: left;
  min-height: 80px;
  background: #003F82;
  color: white;
}

.nav-links {
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: left;
  list-style: none;
}

.nav-link {

  color: white;
  text-decoration: none;
}

.nav-box {
  padding: 4px;
  width: 150px;
  border-width: 1px;
  border: transparent;
  border-style: solid;
  vertical-align: bottom;
  text-align: center;
}

.nav-box:hover {
  border: white;
  border-style: solid;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  min-height: 50px;
  background-color: #003F82;
  color: white;
  vertical-align: middle;
}

.footer-text {
  text-align: right;
  margin-right: 30px;
}

.main-area {
  padding-top: 20px;
  padding-bottom: 80px;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.main-area-content {
  width: 600px;
  display: flex;
  justify-content: flex-end;
}